import { Component, OnInit } from '@angular/core';
import { MyFileService } from 'src/app/_services/myfile.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { MyFilePage } from 'src/app/_models/myfilepage';
import { HelperService } from 'src/app/_services/helper.service';
import { MyFile } from 'src/app/_models/myfile';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-myfile-list',
  templateUrl: './myfile-list.component.html',
  styleUrls: ['./myfile-list.component.css']
})
export class MyFileListComponent implements OnInit {
  myfilepage: MyFilePage;
  model: any = {};

  constructor(
    private myfileService: MyFileService,
    private helperService: HelperService,
    private alertify: AlertifyService,
    private fileSaver: FileSaverService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.myfilepage = data.myfilepage;
    });
  }

  applyFilter(): void {
    this.myfileService
      .getMyFileFiltered(0, this.myfilepage.rSize, this.model.keyword)
      .subscribe(
        data => {
          this.myfilepage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  clearFilter(): void {
    this.model.keyword = '';
    this.myfileService
      .getMyFileFiltered(
        0,
        20,
        null
      )
      .subscribe(
        data => {
          this.myfilepage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  prev(): void {
    const offset = this.myfilepage.rOffset - this.myfilepage.rSize;

    this.myfileService
      .getMyFileFiltered(
        offset < 0 ? 0 : offset,
        this.myfilepage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.myfilepage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  next(): void {
    this.myfileService
      .getMyFileFiltered(
        this.myfilepage.rOffset + this.myfilepage.rSize,
        this.myfilepage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.myfilepage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  reload(): void {
    this.myfileService
      .getMyFileFiltered(
        this.myfilepage.rOffset,
        this.myfilepage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.myfilepage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  download(myFile: MyFile) {
    this.myfileService.downloadFile(myFile).subscribe(
      data => {
        if (data != null) {
          this.fileSaver.save(data, myFile.fileName);
        } else {
          this.alertify.message('Empty file');
        }
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  delete(myFile: MyFile) {
    this.alertify.confirm('Are you sure you want to delete this file?', () => {
      this.myfileService.deleteFile(myFile).subscribe(
        () => {
          this.alertify.success('File has been deleted');
          if (this.myfilepage.myFiles.length > 1) {
            this.reload();
          } else if (this.myfilepage.rOffset > 0) {
            this.prev();
          } else {
            this.myfilepage.myFiles.splice(
              this.myfilepage.myFiles.findIndex(f => f.id === myFile.id),
              1
            );
          }
        },
        error => {
          this.alertify.error('Failed to delete file');
        }
      );
    });
  }

  isSupportedImage(type: string): boolean {
    return this.helperService.isSupportedImage(type);
  }

  isSupportedDoc(type: string): boolean {
    return this.helperService.isSupportedDoc(type);
  }

  glbviewer(id) {
    window.open('/view/glb/' + id, 'noopener noreferer');
  }

  gltfviewer(id) {
    window.open('/view/gltf/' + id, 'noopener noreferer');
  }

  objviewer(id) {
    window.open('/view/obj/' + id, 'noopener noreferer');
  }

  stlviewer(id) {
    window.open('/view/stl/' + id, 'noopener noreferer');
  }

  imageviewer(id) {
    window.open('/view/image/' + id, 'noopener noreferer');
  }

  htmlviewer(id) {
    window.open('/view/html/' + id, 'noopener noreferer');
  }

}

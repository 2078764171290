<div class="container my-5">
  <div class="row">
    <div class="col justify-content-center text-center">
      <div class="card">
        <h5 class="card-header">User Guide</h5>
        <div class="card-body">
          <h5 class="card-title">For Student</h5>
          <p class="card-text">Preliminary version 0.1, June 2019</p>
          <a
            href="/assets/ug-student.pdf"
            target="_blank"
            class="btn btn-primary"
            >User Guide for Student</a
          >
          <hr />
          <h5 class="card-title">For Teacher</h5>
          <p class="card-text">Preliminary version 0.1, June 2019</p>
          <a
            href="/assets/ug-teacher.pdf"
            target="_blank"
            class="btn btn-primary"
            >User Guide for Teacher</a
          >
          <hr />
          <h5 class="card-title">For Administrator</h5>
          <p class="card-text">Preliminary version 0.1, June 2019</p>
          <a
            href="/assets/ug-administrator.pdf"
            target="_blank"
            class="btn btn-primary"
            >User Guide for Administrator</a
          >
        </div>
      </div>
      <hr />
      <div class="card">
        <div class="card-header">
          FAQ
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a
              href="https://docs.microsoft.com/en-us/windows/mixed-reality/gaze"
              target="_blank"
              >Gaze on Microsoft HoloLens</a
            >
          </li>
          <li class="list-group-item">
            <a
              href="https://docs.microsoft.com/en-us/windows/mixed-reality/gestures"
              target="_blank"
              >Gestures on Microsoft HoloLens</a
            >
          </li>
          <li class="list-group-item">
            <a
              href="https://docs.microsoft.com/en-us/windows/mixed-reality/motion-controllers"
              target="_blank"
              >Motion controllers on Microsoft HoloLens</a
            >
          </li>
          <li class="list-group-item">
            <a
              href="https://docs.microsoft.com/en-us/windows/mixed-reality/voice-input"
              target="_blank"
              >Voice input on Microsoft HoloLens</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/_services/admin.service';
import { AlertifyService } from 'src/app/_services/alertify.service';

@Component({
  selector: 'app-courses-add',
  templateUrl: './courses-add.component.html',
  styleUrls: ['./courses-add.component.css']
})
export class CoursesAddComponent implements OnInit {
  model: any = {};

  constructor(
    private adminService: AdminService,
    private alertify: AlertifyService
  ) {}

  ngOnInit() {}

  add(): void {
    this.adminService.addCourse(this.model).subscribe(
      data => {
        this.alertify.success(this.model.courseCode + ' created');
        this.model = {};
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
}

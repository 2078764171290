<div class="container-fluid mt-4">
  <!-- Heading -->
  <div class="col text-center border border-primary rounded-pill">
    <h2 class="p-2">Course Enrollment Management</h2>
    <h4 class="p-2">Add New Enrollment</h4>
  </div>
  <!-- course information -->
  <div class="row m-2">
    <div class="col-sm-1">
      <div class="icon-wrapper text-center mt-2">
        <img src="assets/3d-modeling.svg" alt="course picture" class="icon" />
      </div>
    </div>
    <div class="col-sm-11">
      <h3>
        <strong>Code:</strong> {{ course.courseCode | uppercase }}
        <strong>Year:</strong> {{ course.year }}
        <strong>Session:</strong>
        {{ course.session }}
      </h3>
      <h4><strong>Description:</strong> {{ course.courseTitle }}</h4>
    </div>
  </div>
  <!-- Form -->
  <div class="row h-100 justify-content-center align-items-center m-4">
    <form #addUserForm="ngForm" class="col-12">
      <h4>Login Names</h4>
      <h6>Please separated by commas (,)</h6>
      <textarea
        class="form-control textHeight"
        rows="6"
        placeholder="login names"
        aria-label="login names"
        [(ngModel)]="model.usernames"
        required
        name="login names"
      ></textarea>
      <hr />
      <button
        class="btn btn-primary m-1"
        [disabled]="!addUserForm.valid"
        (click)="add()"
      >
        <span class="fa fa-plus"></span> Submit
      </button>
    </form>
  </div>
  <!-- Result -->
  <hr />
  <div class="row h-100 justify-content-center align-items-center m-4">
    <div class="col-md">
      <h6>Added</h6>
      <textarea rows="6">{{ result.added }}</textarea>
    </div>
    <div class="col-md">
      <h6>Skipped</h6>
      <textarea rows="6" class="bg-warning">{{ result.skipped }}</textarea>
    </div>
    <div class="col-md">
      <h6>Failed</h6>
      <textarea rows="6" class="bg-danger">{{ result.failed }}</textarea>
    </div>
  </div>
</div>

<div class="container-fluid mt-4">
  <!-- course information -->
  <div class="row m-2">
    <div class="col-sm-1">
      <div class="icon-wrapper text-center mt-2">
        <img src="assets/3d-modeling.svg" alt="course picture" class="icon" />
      </div>
    </div>
    <div class="col-sm-11">
      <h3>
        <strong>Name</strong> {{ print3d.name | uppercase }}
        <strong>Description</strong> {{ print3d.description }}
      </h3>
      <h4><strong>Location:</strong> {{ print3d.location }}</h4>
    </div>
  </div>
  <div class="row">
    <!-- Details -->
    <div class="col-sm-6">
      <div>
        <h3 class="text-center"><strong>Camera View</strong></h3>
        <img class="image" src="{{ imageUrl }}" alt="3D Printer Image" />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="mx-4">
      <h3 class="text-center"><strong>Printer Status</strong></h3>
      <!-- <h6 class="text-center">{{ print3d.status }}</h6> -->
      <p>Job: HW104-3DP1-20220601-00001</p>
      <p>Filename: testing_block.gcode</p>
      <p>Date: 01 June 2022</p>
      <p>Progress: 40%</p>
      <p>Estimated Total Print Time: 0 day, 4 hour, 20 minutes</p>
      <p>Head temperature: 200 <span>&#8451;</span></p>
      <button class="mx-2">Pause</button>
      <button class="mx-2">Abort</button>
<!--       <button (click)="openFile()">Send gcode to printer
        <input type="file" style="display:none" (change)="handle($event)">
      </button>
 -->
      <p>{{ content }}</p>
    </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-4">
  <!-- Heading -->
  <div class="col text-center border border-primary rounded-pill">
    <h2 class="p-2">Course Management</h2>
    <h4 class="p-2">Edit Course</h4>
  </div>
  <!-- Form -->
  <div class="row h-100 justify-content-center align-items-center m-4">
    <form #addUserForm="ngForm" class="col-12">
      <h4>Course Code</h4>
      <input
        class="form-control"
        placeholder="course code"
        aria-label="course code"
        [(ngModel)]="model.courseCode"
        required
        name="course code"
      />
      <h4>Course Title</h4>
      <input
        class="form-control"
        placeholder="course title"
        aria-label="email"
        [(ngModel)]="model.courseTitle"
        required
        name="course title"
      />
      <h4>Year</h4>
      <input
        class="form-control"
        placeholder="year"
        aria-label="year"
        [(ngModel)]="model.year"
        required
        name="year"
        type="number"
      />
      <h4>Session</h4>
      <input
        class="form-control"
        placeholder="session"
        aria-label="session"
        [(ngModel)]="model.session"
        required
        name="session"
      />
      <hr />
      <button
        class="btn btn-primary m-1"
        [disabled]="!addUserForm.valid"
        (click)="update()"
      >
        <span class="fa fa-pencil"></span> Update
      </button>
    </form>
  </div>
</div>

import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CoursesListComponent } from './course/courses-list/courses-list.component';
import { CourseDetailComponent } from './course/course-detail/course-detail.component';
import { CourseFileUploadComponent } from './course/course-file-upload/course-file-upload.component';
import { AdminComponent } from './admin/admin/admin.component';
import { AuthGuard } from './_guards/auth.guard';
import { CourseDetailResolver } from './_resolver/course-detail.resolver';
import { CourseListResolver } from './_resolver/course-list.resolver';
import { CourseFileResolver } from './_resolver/course-file.resolver';
import { CourseFileEditComponent } from './course/course-file-edit/course-file-edit.component';
import { CoursesComponent } from './admin/courses/courses.component';
import { AdminUsersResolver } from './_resolver/admin-users.resolver';
import { UsersListComponent } from './admin/users/users-list.component';
import { UsersAddComponent } from './admin/users-add/users-add.component';
import { UsersEditComponent } from './admin/users-edit/users-edit.component';
import { AdminUserResolver } from './_resolver/admin-user.resolver';
import { AdminCoursesResolver } from './_resolver/admin-courses.resolver';
import { CoursesAddComponent } from './admin/courses-add/courses-add.component';
import { AdminCourseResolver } from './_resolver/admin-course.resolver';
import { CoursesEditComponent } from './admin/courses-edit/courses-edit.component';
import { CoursesEnrollmentComponent } from './admin/courses-enrollment/courses-enrollment.component';
import { AdminCourseEnrollmentResolver } from './_resolver/admin-course-enrollment.resolver';
import { CoursesEnrollmentAddComponent } from './admin/courses-enrollment-add/courses-enrollment-add.component';
import { BatchComponent } from './admin/batch/batch.component';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { DocViewerComponent } from './course/doc-viewer/doc-viewer.component';
import { HelpComponent } from './help/help/help.component';
import { MyFileListResolver } from './_resolver/myfile-list.resolver';
import { MyFileListComponent } from './myfile/myfile-list/myfile-list.component';
import { MyFileUploadComponent } from './myfile/myfile-upload/myfile-upload.component';
import { MyFileEditComponent } from './myfile/myfile-edit/myfile-edit.component';
import { MyFileFileResolver } from './_resolver/myfile-file.resolver';
import { Print3dsListComponent } from './print3d/print3ds-list/print3ds-list.component';
import { Print3dsListResolver } from './_resolver/print3d-list.resolver';
import { Print3dDetailsComponent } from './print3d/print3d-details/print3d-details.component';
import { Print3dDetailsResolver } from './_resolver/print3d-details.resolver';

export const appRoute: Routes = [
  { path: '', component: HomeComponent },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'courses',
        component: CoursesListComponent,
        resolve: { courses: CourseListResolver }
      },
      {
        path: 'course/:id',
        component: CourseDetailComponent,
        resolve: { course: CourseDetailResolver }
      },
      {
        path: 'course-file-upload/:id',
        component: CourseFileUploadComponent,
        resolve: { course: CourseDetailResolver }
      },
      {
        path: 'course-file-edit/:cid/:fid',
        component: CourseFileEditComponent,
        resolve: { datafile: CourseFileResolver }
      },
      {
        path: 'docviewer/:cid/:fid',
        component: DocViewerComponent,
        resolve: { datafile: CourseFileResolver }
      },
      { path: 'admin', component: AdminComponent },
      {
        path: 'admin/users',
        component: UsersListComponent,
        resolve: { userpage: AdminUsersResolver }
      },
      { path: 'admin/users/add', component: UsersAddComponent },
      {
        path: 'admin/users/edit/:uname',
        component: UsersEditComponent,
        resolve: { user: AdminUserResolver }
      },
      {
        path: 'admin/courses',
        component: CoursesComponent,
        resolve: { coursepage: AdminCoursesResolver }
      },
      { path: 'admin/courses/add', component: CoursesAddComponent },
      {
        path: 'admin/courses/edit/:id',
        component: CoursesEditComponent,
        resolve: { course: AdminCourseResolver }
      },
      {
        path: 'admin/courses/enrollment/:id',
        component: CoursesEnrollmentComponent,
        resolve: { enrollmentpage: AdminCourseEnrollmentResolver }
      },
      {
        path: 'admin/courses/enrollment/add/:id',
        component: CoursesEnrollmentAddComponent,
        resolve: { course: AdminCourseResolver }
      },
      { path: 'admin/batch', component: BatchComponent },
      { path: 'user/password/change', component: UserChangePasswordComponent },
      {
        path: 'myfiles',
        component: MyFileListComponent,
        resolve: { myfilepage: MyFileListResolver }
      },
      {
        path: 'myfiles-upload',
        component: MyFileUploadComponent,
        resolve: { myfilepage: MyFileListResolver }
      },
      {
        path: 'myfiles-edit/:id',
        component: MyFileEditComponent,
        resolve: { myfile: MyFileFileResolver }
      },
      {
        path: 'print3ds',
        component: Print3dsListComponent,
        resolve: { print3dspage: Print3dsListResolver }
      },
      {
        path: 'print3d/:id',
        component: Print3dDetailsComponent,
        resolve: { print3d: Print3dDetailsResolver }
      },
    ]
  },
  { path: 'help', component: HelpComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

<div class="container-fluid mt-4">
  <!-- course information -->
  <div class="row m-2">
    <div class="col-sm-1">
      <div class="icon-wrapper text-center mt-2">
        <img src="assets/3d-modeling.svg" alt="course picture" class="icon" />
      </div>
    </div>
    <div class="col-sm-11">
      <h3>
        <strong>Code:</strong> {{ course.courseCode | uppercase }}
        <strong>Year:</strong> {{ course.year }} <strong>Session:</strong>
        {{ course.session }}
      </h3>
      <h4><strong>Description:</strong> {{ course.courseTitle }}</h4>
      <button class="btn btn-info" *ngIf="isStaff()" [routerLink]="['/course-file-upload/', course.courseId]">
        <i class="fa fa-upload"></i> Upload File
      </button>
    </div>
  </div>
  <div class="row h-100 align-items-center m-4">
    <div class="col-4">
      <button class="btn btn-primary m-1" [disabled]="course.rOffset === 0" (click)="prev()">
        <span class="fa fa-step-backward"></span> Previous
      </button>
      <button class="btn btn-primary m-1" [disabled]="course.totalSize <= (course.rOffset + course.rSize)"
        (click)="next()">
        Next
        <span class="fa fa-step-forward"></span>
      </button>
      <h4 class="align-middle">
        <strong>Files Found: </strong> {{ course.totalSize }}
      </h4>
    </div>
    <div class="col-8">
      <form #filterFileForm="ngForm" class="col-12">
        <h6>Filter file by:</h6>
        <input class="form-control" placeholder="single keyword" aria-label="keyword" [(ngModel)]="model.keyword"
          required name="keyword" />
        <button class="btn btn-primary m-1" [disabled]="!filterFileForm.valid" (click)="applyFilter()">
          <span class="fa fa-filter"></span> Apply Filter
        </button>
        <button class="btn btn-primary m-1" [disabled]="!filterFileForm.dirty" (click)="clearFilter()">
          <span class="fa fa-ban"></span> Clear Filter
        </button>
      </form>
    </div>
  </div>
  <div class="row">
    <!-- course files -->
    <div class="table-wrapper m-1">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="w-10">#</th>
            <th class="w-10">Icon</th>
            <th class="w-10">File Name</th>
            <th class="w-50">Description</th>
            <th class="w-20">Last Modified</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let df of course.dataFiles; index as i">
            <th scope="row">
              {{ course.rOffset + i + 1 }}
            </th>
            <td>
              <img src="{{ df.iconUrl }}" alt="file icon" class="file-icon" />
            </td>
            <td>{{ df.fileName }}</td>
            <td>
              {{ df.description }}<br />{{
                df?.length | formatFileSize
              }}
              <br />
              <div class="btn-group d-flex">
                <button class="btn btn-primary" (click)="download(df)">
                  <i class="fa fa-download"></i> Download
                </button>
                <button class="btn btn-info" (click)="stlviewer(df.id)" *ngIf="df.fileType === 'stl'">
                  <i class="fa fa-eye"></i> VR View
                </button>
                <button class="btn btn-info" (click)="objviewer(df.id)" *ngIf="df.fileType === 'obj'">
                  <i class="fa fa-eye"></i> VR View
                </button>
                <button class="btn btn-info" (click)="gltfviewer(df.id)" *ngIf="df.fileType === 'gltf'">
                  <i class="fa fa-eye"></i> VR View
                </button>
                <button class="btn btn-info" (click)="glbviewer(df.id)" *ngIf="df.fileType === 'glb'">
                  <i class="fa fa-eye"></i> VR View
                </button>
                <button class="btn btn-info" (click)="imageviewer(df.id)" *ngIf="isSupportedImage(df.fileType)">
                  <i class="fa fa-eye"></i> View
                </button>
                <button class="btn btn-info" (click)="htmlviewer(df.id)" *ngIf="df.fileType === 'html'">
                  <i class="fa fa-eye"></i> View
                </button>
                <button class="btn btn-warning" *ngIf="isStaff()"
                  [routerLink]="['/course-file-edit/', df.courseId, df.id]">
                  <i class="fa fa-edit"></i> Edit
                </button>
                <button class="btn btn-danger" *ngIf="isStaff()" (click)="delete(df)">
                  <i class="fa fa-trash"></i> Delete
                </button>
              </div>
            </td>
            <td class="date-col">
              <h6>
                {{ df.lastModified | date: "mediumDate" }}<br />{{
                  df.lastModified | date: "mediumTime"
                }}
              </h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models/user';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.css']
})
export class UsersEditComponent implements OnInit {
  user: User;
  model: any = {};

  constructor(
    private adminService: AdminService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.user = data.user;
    });

    this.model.userName = this.user.userName;
    this.model.email = this.user.email;
    this.model.displayName = this.user.displayName;
    this.model.description = this.user.description;
    this.model.type = this.user.type;
  }

  update(): void {
    this.adminService.updateUser(this.model).subscribe(() => {
      this.alertify.success(this.model.displayName + ' updated');
    }, error => {
      this.alertify.error('Cannot Update User Information');
    });
  }
}

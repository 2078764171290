<div class="container-fluid mt-4">
  <!-- Heading -->
  <div class="col text-center border border-primary rounded-pill">
    <h2 class="p-2">Course Management</h2>
  </div>
  <!-- Buttons -->
  <div class="row h-100 justify-content-center align-items-center m-4">
    <button
      class="btn btn-primary m-1 text-center"
      [routerLink]="['/admin/courses/add']"
    >
      <span class="fa fa-graduation-cap"></span> Add New Course
    </button>
  </div>
  <!-- Table -->
  <div class="col text-center border border-dark rounded-pill">
    <h4 class="p-1">Course List</h4>
  </div>
  <div class="row h-100 align-items-center m-4">
    <div class="col-4">
      <button class="btn btn-primary m-1" [disabled]="coursepage.rOffset === 0" (click)="prev()">
        <span class="fa fa-step-backward"></span> Previous
      </button>
      <button
        class="btn btn-primary m-1"
        [disabled]="coursepage.totalSize <= (coursepage.rOffset + coursepage.rSize)"
        (click)="next()"
      >
        Next
        <span class="fa fa-step-forward"></span>
      </button>
      <h4 class="align-middle">
        <strong>Courses Found: </strong> {{ coursepage.totalSize }}
      </h4>
    </div>
    <div class="col-8">
      <form #filterFileForm="ngForm" class="col-12">
        <h4>Filter</h4>
        <h6>Course Code</h6>
        <input
          class="form-control"
          placeholder="course code"
          aria-label="course code"
          [(ngModel)]="model.rCourseCode"
          name="course code"
        />
        <h6>Course Title</h6>
        <input
          class="form-control"
          placeholder="course title"
          aria-label="course title"
          [(ngModel)]="model.rCourseTitle"
          name="course title"
        />
        <h6>Year</h6>
        <input
          class="form-control"
          placeholder="year"
          aria-label="year"
          [(ngModel)]="model.rYear"
          name="year"
          type="number"
        />
        <h6>Session</h6>
        <input
          class="form-control"
          placeholder="session"
          aria-label="session"
          [(ngModel)]="model.rSession"
          name="session"
        />
        <button class="btn btn-primary m-1" [disabled]="!isAnyFilterInput()" (click)="applyFilter()">
          <span class="fa fa-filter"></span> Apply Filter
        </button>
        <button class="btn btn-primary m-1" [disabled]="!filterFileForm.dirty" (click)="clearFilter()">
          <span class="fa fa-ban"></span> Clear Filter
        </button>
      </form>
    </div>
  </div>
  <div class="row">
    <!-- course files -->
    <div class="container-fluid m-1">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="tc1">#</th>
            <th class="tc2">Course Code</th>
            <th class="tc3">Course Title</th>
            <th class="tc4">Year</th>
            <th class="tc5">Session</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let c of coursepage.courses; index as i">
            <th scope="row">
              <!-- # -->
              {{ coursepage.rOffset + i + 1 }}
            </th>
            <td>
              <!-- course code -->
              {{ c.courseCode }}
            </td>
            <td>
              <!-- course title -->
              <p>{{ c.courseTitle }}</p>
              <div class="btn-group d-flex">
                <button
                  class="btn btn-primary m-1"
                  [routerLink]="['/admin/courses/edit', c.courseId]"
                >
                  <i class="fa fa-edit"></i> Edit
                </button>
                <button class="btn btn-primary m-1" (click)="delete(c)">
                  <i class="fa fa-trash"></i> Delete
                </button>
                <button class="btn btn-primary m-1" [routerLink]="['/admin/courses/enrollment', c.courseId]">
                  <i class="fa fa-users"></i> Enrollment
                </button>
              </div>
            </td>
            <td>
              <!-- year -->
              <div>{{ c.year }}</div>
            </td>
            <td>
              <!-- session -->
              {{ c.session }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

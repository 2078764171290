import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Print3dPage } from '../_models/print3dpage';
import { Print3d } from '../_models/print3d';

@Injectable({
  providedIn: 'root'
})
export class Print3dService {

baseUrl = environment.apiUrl + 'print3d/';

constructor(private http: HttpClient) {}

getPrint3dsFiltered(start: number, size: number, model: any): Observable<Print3dPage> {
  if (model === null) {
    return this.http.get<Print3dPage>(this.baseUrl + start + '/' + size);
  }

  return this.http.get<Print3dPage>(this.baseUrl + start + '/' + size + '/' + model);
}

getPrint3d(id: number): Observable<Print3d> {
  return this.http.get<Print3d>(this.baseUrl + id);
}

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.css']
})
export class UserChangePasswordComponent implements OnInit {
  model: any = {};
  constructor(
    private authService: AuthService,
    private alertify: AlertifyService
  ) {}

  ngOnInit() {}

  changePassword() {
    this.authService.changePassword(this.model).subscribe(
      next => {
        this.alertify.success('Password Changed');
      },
      error => {
        this.alertify.error(error);
      }
    );
    // clear user login information
    this.model = {};

  }
}

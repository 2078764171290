<form #registerForm="ngForm" (ngSubmit)="register()">
  <h2 class="text-center text-primary">Sign Up</h2>
  <hr>

  <div class="form-group">
    <input type="text" class="form-control" required name="username" 
      placeholder="Username" [(ngModel)]="model.username">
  </div>

  <div class="form-group">
    <input type="password" class="form-control" required name="password" 
      placeholder="Password" [(ngModel)]="model.password">
  </div>

  <div class="form-group text-center">
    <button class="btn btn-success" type="submit">Register</button>
    <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
<div class="container-fluid mt-4">
  <!-- Heading -->
  <div class="col text-center border border-primary rounded-pill">
    <h2 class="p-2">Batch Operation</h2>
    <h4 class="p-2">
      Upload excel file to add / update users, courses, or enrollments
    </h4>
  </div>
  <!-- Form -->
  <div class="row h-100 justify-content-center align-items-center m-4">
    <form #batchForm="ngForm" class="col-12">
      <!-- Radio Buttons -->
      <div class="row mt-3">
        <div
          class="btn-group btn-group-toggle"
          ngbRadioGroup
          name="radioBasic"
          required
          [(ngModel)]="model.type"
        >
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" value="users" /> Users
          </label>
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" value="courses" /> Courses
          </label>
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" value="enrollments" /> Enrollments
          </label>
        </div>
        <hr />
      </div>
      <!-- Upload File Form -->
      <div class="row mt-3">
        <div class="col-md-3">
          <h3>Add File</h3>

          <!-- drop zone -->
          <div
            ng2FileDrop
            [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
            (fileOver)="fileOverBase($event)"
            [uploader]="uploader"
            class="card bg-faded p-3 text-center my-drop-zone"
          >
            <i class="fa fa-upload fa-3x"></i>
            Drop File Here
          </div>

          <!-- file button -->
          <input type="file" ng2FileSelect [uploader]="uploader" />
        </div>

        <div
          class="col-md-9"
          style="margin-bottom: 40px"
          *ngIf="uploader?.queue?.length"
        >
          <h3>Upload queue</h3>
          <p>Queue length: {{ uploader?.queue?.length }}</p>

          <table class="table">
            <thead>
              <tr>
                <th width="80%">Name</th>
                <th>Size</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of uploader.queue">
                <td>
                  <strong>{{ item?.file?.name }}</strong>
                </td>
                <td nowrap>
                  {{ item?.file?.size / 1024 / 1024 | number: ".2" }} MB
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <div>
              Queue progress:
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngStyle]="{ width: uploader.progress + '%' }"
                ></div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-success btn-s"
              (click)="upload()"
              [disabled]="!uploader.getNotUploadedItems().length"
            >
              <span class="fa fa-upload"></span> Upload
            </button>
            <button
              type="button"
              class="btn btn-warning btn-s"
              (click)="uploader.cancelAll()"
              [disabled]="!uploader.isUploading"
            >
              <span class="fa fa-ban"></span> Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger btn-s"
              (click)="uploader.clearQueue()"
              [disabled]="!uploader.queue.length"
            >
              <span class="fa fa-trash"></span> Remove
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- Result -->
  <hr />
  <div class="row h-100 justify-content-center align-items-center m-4">
    <div class="col-md">
      <h6>Added</h6>
      <textarea rows="6">{{ result.added }}</textarea>
    </div>
    <div class="col-md">
      <h6>Skipped</h6>
      <textarea rows="6" class="bg-warning">{{ result.skipped }}</textarea>
    </div>
    <div class="col-md">
      <h6>Failed</h6>
      <textarea rows="6" class="bg-danger">{{ result.failed }}</textarea>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Course } from 'src/app/_models/course';
import { CourseService } from 'src/app/_services/course.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course-file-upload',
  templateUrl: './course-file-upload.component.html',
  styleUrls: ['./course-file-upload.component.css']
})
export class CourseFileUploadComponent implements OnInit {
  course: Course;
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  model: any = {};

  constructor(
    private courseService: CourseService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.course = data.course;
    });

    this.initFileUploader();

    this.model.courseId = this.course.courseId;
  }

  fileOverBase(e: any) {
    this.hasBaseDropZoneOver = e;
  }

  initFileUploader() {
    this.uploader = new FileUploader({
      url: this.baseUrl + 'courses/uploadfile',
      authToken: 'Bearer ' + sessionStorage.getItem('token'),
      isHTML5: true,
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 100 * 1024 * 1024
    });

    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };
  }

  upload() {
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('courseId', this.model.courseId);
      form.append('description', this.model.description);
    };
    this.uploader.uploadAll();
    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: any
    ): any => {
      this.alertify.success(item.file.name + ' Upload Success');
      this.model.description = null;
    };
    this.uploader.onErrorItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: any
    ): any => {
      this.alertify.error(item.file.name + ' Upload Failed');
    };
  }
}

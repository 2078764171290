import { Component, OnInit } from '@angular/core';
import { AuthService } from './_services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ClpSpa';

  constructor(public authService: AuthService) {}

  ngOnInit() {
    this.authService.decodeToken();
  }

  onActivate(event) {
    // console.log('onActivate');
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      // console.log(pos);
      if (pos > 0) {
        window.scrollTo(0, pos - 50); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}

<div class="container text-center">
  <h3><i class="fa fa-file-text-o"></i> {{ dataFile.fileName }}</h3>
</div>

<div class="viewer">
  <!-- <object
    [data]="this.docUrl"
    [type]="this.docType"
    class="img-fluid rounded mx-auto d-block px-2"
    *ngIf="this.docUrl !== ''"
  ></object> -->
  <iframe
    src="/assets/hello.html"
    width="100%"
    height="500"
    frameborder="0"
    allowfullscreen
    sandbox="allow-same-origin allow-scripts"
    >
    <p>
      <a href="/assets/hello.html">
        Fallback link for browsers that don't support iframes
      </a>
    </p></iframe
  >
</div>

import { Component, OnInit } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.css']
})
export class BatchComponent implements OnInit {
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  model: any = {};
  result: any = {};

  constructor(
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.model.type = 'users';
    this.initFileUploader();
  }

  fileOverBase(e: any) {
    this.hasBaseDropZoneOver = e;
  }

  initFileUploader() {
    this.uploader = new FileUploader({
      url: this.baseUrl + 'admin/' + this.model.type + '/batch',
      authToken: 'Bearer ' + sessionStorage.getItem('token'),
      isHTML5: true,
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 20 * 1024 * 1024
    });

    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };

    this.uploader.onBeforeUploadItem = item => {
      item.url = this.baseUrl + 'admin/' + this.model.type + '/batch';
    };
  }

  upload() {
    this.result = {};
    this.uploader.uploadAll();
    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: any
    ): any => {
      this.result = JSON.parse(response);
      if (this.result.failed.length > 0) {
        this.alertify.error('Please review the error list');
      } else if (this.result.skipped.length > 0 ) {
        this.alertify.warning('Please review the warning list');
      } else {
        this.alertify.success('Batch Operation Done');
      }
    };
    this.uploader.onErrorItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: any
    ): any => {
      this.alertify.error('Batch Operation Failed');
    };
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { MyFilePage } from '../_models/myfilepage';
import { MyFile } from '../_models/myfile';

@Injectable({
  providedIn: 'root'
})
export class MyFileService {

  baseUrl = environment.apiUrl + 'myfiles/';

  constructor(private http: HttpClient) {}

  getMyFileFiltered(start: number, size: number, keyword: string): Observable<MyFilePage> {
    if (keyword == null) {
      return this.http.get<MyFilePage>(this.baseUrl + start + '/' + size);
    }
    return this.http.get<MyFilePage>(this.baseUrl + start + '/' + size + '/' + keyword);
  }

  getMyFile(fid): Observable<MyFile> {
    return this.http.get<MyFile>(this.baseUrl + fid + '/');
  }

  deleteFile(df: MyFile) {
    return this.http.delete(this.baseUrl + df.id);
  }

  downloadFile(df: MyFile): Observable<Blob> {
    const url = this.baseUrl + df.id + '/' + df.fileName;
    const file = this.http.get<Blob>(url, {responseType: 'blob' as 'json'});

    return file;
  }

  updateFile(model: any) {
    const url = this.baseUrl + model.id;
    return this.http.put(url, model);
  }

}

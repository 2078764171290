import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/_services/admin.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { Course } from 'src/app/_models/course';

@Component({
  selector: 'app-courses-edit',
  templateUrl: './courses-edit.component.html',
  styleUrls: ['./courses-edit.component.css']
})
export class CoursesEditComponent implements OnInit {
  course: Course;
  model: any = {};

  constructor(
    private adminService: AdminService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.course = data.course;
    });

    this.model.courseId = this.course.courseId;
    this.model.courseCode = this.course.courseCode;
    this.model.courseTitle = this.course.courseTitle;
    this.model.year = this.course.year;
    this.model.session = this.course.session;
  }

  update(): void {
    this.adminService.updateCourse(this.model).subscribe(
      () => {
        this.alertify.success(this.model.courseCode + ' updated');
      },
      error => {
        this.alertify.error('Cannot Update Course Information');
      }
    );
  }
}

<div class="container mt-5">
  <div class="row">
    <div class="col justify-content-center text-center">
      <h3>Welcome to Cloud-enabled Experiential Learning Platform</h3>
      <h6>Department of Industrial and Manufacturing Systems Engineering</h6>
      <h6>The University of Hong Kong</h6>
      <hr />
      <h6 [hidden]="isLogin()">Please login to access resources</h6>
    </div>
  </div>
  <!-- <div>
    <a href='https://yahoo.com' target="_blank">Yahoo</a>
    <br>
    <a href='https://google.com' target="_blank">Google</a>
    <br>
    <a href='https://sh760:4200/view/obj/1557923878-male02.obj' target="_blank">View file 4 with obj viewer</a>
    <br>
    <a href='https://sh760:4200/view/obj/4' target="_blank">View file 4 with obj viewer</a>
    <br>
    <a href='https://sh760:4200/view/file/1557923878-male02.obj' target="_blank">Get file male02.obj</a>
    <br>
    <a href='https://sh760:4200/view/stl/1569323562-peter.stl' target="_blank">View file 5 with stl viewer</a>
    <br>
    <a href='https://sh760:4200/view/stl/5' target="_blank">View file 5 with stl viewer</a>
    <br>
    <a href='https://sh760:4200/view/file/1569323562-peter.stl' target="_blank">Get file peter.stl</a>
    <br>
    <a href='https://sh760:4200/view/gltf/1569554059-DamagedHelmet.gltf' target="_blank">View file 6 with gltf viewer</a>
    <br>
    <a href='https://sh760:4200/view/gltf/6' target="_blank">View file 6 with gltf viewer</a>
    <br>
    <a href='https://sh760:4200/view/file/1569554059-DamagedHelmet.gltf' target="_blank">Get file DamagedHelmet.gltf</a>
    <br>
    <a href='https://sh760:4200/view/glb/1569554087-DamagedHelmet.glb' target="_blank">View file 6 with glb viewer</a>
    <br>
    <a href='https://sh760:4200/view/glb/7' target="_blank">View file 6 with glb viewer</a>
    <br>
    <a href='https://sh760:4200/view/file/1569554087-DamagedHelmet.glb' target="_blank">Get file DamagedHelmet.glb</a>
  </div>
-->
  <!-- <div *ngIf="!registerMode" class="text-center">
    <h1>Browse your files</h1>
    <p class="lead">Please sign up</p>
    <div class="text-center">
      <button class="btn btn-primary btn-lg mr-2" (click)="registerToggle()">Register</button>
      <button class="btn btn-info btn-lg">Learn More</button>
    </div>
  </div>

  <div *ngIf="registerMode" class="container">
    <div class="row justify-content-center">
      <div class="col-4">
        <app-register (cancelRegister)="cancelRegisterMode($event)"></app-register>
      </div>
    </div>
  </div> -->
</div>

<div class="container-fluid mt-4">
  <!-- Heading -->
  <div class="col text-center border border-primary rounded-pill">
    <h2 class="p-2">User Management</h2>
    <h4 class="p-2">Add New User</h4>
  </div>
  <!-- Form -->
  <div class="row h-100 justify-content-center align-items-center m-4">
    <form #addUserForm="ngForm" class="col-12">
      <h4>Login Name</h4>
      <input
        class="form-control"
        placeholder="Login Name"
        aria-label="username"
        [(ngModel)]="model.username"
        required
        name="username"
      />
      <h4>Password</h4>
      <input
        type="password"
        class="form-control"
        required
        name="password"
        placeholder="Password"
        [(ngModel)]="model.password"
      />
      <h4>Email Address</h4>
      <input
        class="form-control"
        placeholder="Email Address"
        aria-label="email"
        [(ngModel)]="model.email"
        required
        name="email"
      />
      <h4>User Name</h4>
      <input
        class="form-control"
        placeholder="User Name"
        aria-label="displayName"
        [(ngModel)]="model.displayName"
        required
        name="displayName"
      />
      <h4>Description</h4>
      <textarea
        class="form-control textHeight"
        rows="6"
        placeholder="Description"
        aria-label="description"
        [(ngModel)]="model.description"
        required
        name="description"
      ></textarea>
      <h4>Type</h4>
      <div
        class="btn-group btn-group-toggle"
        ngbRadioGroup
        name="radioBasic"
        required
        [(ngModel)]="model.type"
      >
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" value="admin" /> Admin
        </label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" value="staff" /> Staff
        </label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" value="student" /> Student
        </label>
      </div>
      <hr />
      <button
        class="btn btn-primary m-1"
        [disabled]="!addUserForm.valid"
        (click)="add()"
      >
        <span class="fa fa-plus-circle"></span> Submit
      </button>
    </form>
  </div>
</div>

<div class="container-fluid mt-4">
  <!-- course information -->
  <div class="row m-2">
    <div class="col-sm-1">
      <div class="icon-wrapper text-center mt-2">
        <img src="assets/3d-modeling.svg" alt="course picture" class="icon" />
      </div>
    </div>
    <div class="col-sm-11">
      <h3>
        <strong>3D Printers</strong>
      </h3>
    </div>
  </div>
  <div class="row h-100 align-items-center m-4">
    <div class="col-4">
      <button class="btn btn-primary m-1" [disabled]="print3dpage.rOffset === 0" (click)="prev()">
        <span class="fa fa-step-backward"></span> Previous
      </button>
      <button class="btn btn-primary m-1" [disabled]="print3dpage.totalSize <= (print3dpage.rOffset + print3dpage.rSize)"
        (click)="next()">
        Next
        <span class="fa fa-step-forward"></span>
      </button>
      <h4 class="align-middle">
        <strong>Files Found: </strong> {{ print3dpage.totalSize }}
      </h4>
    </div>
    <div class="col-8">
      <form #filterFileForm="ngForm" class="col-12">
        <h6>Filter printer by:</h6>
        <input class="form-control" placeholder="single keyword" aria-label="keyword" [(ngModel)]="model.keyword"
          required name="keyword" />
        <button class="btn btn-primary m-1" [disabled]="!filterFileForm.valid" (click)="applyFilter()">
          <span class="fa fa-filter"></span> Apply Filter
        </button>
        <button class="btn btn-primary m-1" [disabled]="!filterFileForm.dirty" (click)="clearFilter()">
          <span class="fa fa-ban"></span> Clear Filter
        </button>
      </form>
    </div>
  </div>
  <div class="row">
    <!-- 3d printers -->
    <div *ngFor="let print3d of print3dpage.print3Ds" class="col-sm-4 col-md-3 p-2">
      <app-print3d-card [print3d]="print3d"></app-print3d-card>
    </div>
  </div>
</div>
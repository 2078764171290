import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor() {}

  isSupportedImage(type: string): boolean {
    switch (type) {
      case 'bmp':
      case 'gif':
      case 'jpg':
      case 'png':
      case 'svg':
      case 'webp':
        return true;
      default:
        return false;
    }
  }

  isSupportedDoc(type: string): boolean {
    switch (type) {
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'ppt':
      case 'pptx':
      case 'txt':
      case 'pdf':
        return true;
      default:
        return false;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/_services/course.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { MyFilePage } from 'src/app/_models/myfilepage';
import { MyFile } from 'src/app/_models/myfile';

@Component({
  selector: 'app-myfile-upload',
  templateUrl: './myfile-upload.component.html',
  styleUrls: ['./myfile-upload.component.css']
})
export class MyFileUploadComponent implements OnInit {
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  model: any = {};
  myfilepage: MyFilePage;

  constructor(
    private courseService: CourseService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.myfilepage = data.myfilepage;
    });

    this.initFileUploader();
  }

  fileOverBase(e: any) {
    this.hasBaseDropZoneOver = e;
  }

  initFileUploader() {
    this.uploader = new FileUploader({
      url: this.baseUrl + 'myfiles/uploadfile',
      authToken: 'Bearer ' + sessionStorage.getItem('token'),
      isHTML5: true,
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 100 * 1024 * 1024
    });

    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };
  }

  upload() {
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('description', this.model.description);
    };
    this.uploader.uploadAll();
    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: any
    ): any => {
      this.alertify.success(item.file.name + ' Upload Success');
      this.model.description = null;
      this.myfilepage = JSON.parse(response);
    };
    this.uploader.onErrorItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: any
    ): any => {
      this.alertify.error(item.file.name + ' Upload Failed');
    };
  }
}

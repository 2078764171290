import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwtModule } from '@auth0/angular-jwt';
import { FileSaverModule } from 'ngx-filesaver';
import { FileUploadModule } from 'ng2-file-upload';

import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { AuthService } from './_services/auth.service';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { AlertifyService } from './_services/alertify.service';
import { CoursesListComponent } from './course/courses-list/courses-list.component';
import { AdminComponent } from './admin/admin/admin.component';
import { appRoute } from './routes';
import { AuthGuard } from './_guards/auth.guard';
import { UserService } from './_services/user.service';
import { CourseService } from './_services/course.service';
import { CourseCardComponent } from './course/course-card/course-card.component';
import { CourseDetailComponent } from './course/course-detail/course-detail.component';
import { CourseFileUploadComponent } from './course/course-file-upload/course-file-upload.component';
import { CourseDetailResolver } from './_resolver/course-detail.resolver';
import { CourseListResolver } from './_resolver/course-list.resolver';
import { CourseFileResolver } from './_resolver/course-file.resolver';
import { CourseFileEditComponent } from './course/course-file-edit/course-file-edit.component';
import { CoursesComponent } from './admin/courses/courses.component';
import { AdminService } from './_services/admin.service';
import { AdminUsersResolver } from './_resolver/admin-users.resolver';
import { UsersListComponent } from './admin/users/users-list.component';
import { UsersAddComponent } from './admin/users-add/users-add.component';
import { UsersEditComponent } from './admin/users-edit/users-edit.component';
import { AdminUserResolver } from './_resolver/admin-user.resolver';
import { AdminCoursesResolver } from './_resolver/admin-courses.resolver';
import { CoursesAddComponent } from './admin/courses-add/courses-add.component';
import { CoursesEditComponent } from './admin/courses-edit/courses-edit.component';
import { AdminCourseResolver } from './_resolver/admin-course.resolver';
import { CoursesEnrollmentComponent } from './admin/courses-enrollment/courses-enrollment.component';
import { AdminCourseEnrollmentResolver } from './_resolver/admin-course-enrollment.resolver';
import { CoursesEnrollmentAddComponent } from './admin/courses-enrollment-add/courses-enrollment-add.component';
import { BatchComponent } from './admin/batch/batch.component';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { DocViewerComponent } from './course/doc-viewer/doc-viewer.component';
import { HelpComponent } from './help/help/help.component';
import { TargetBlankDirective } from './_directives/target-blank.directive';
import { MyFileListComponent } from './myfile/myfile-list/myfile-list.component';
import { MyFileListResolver } from './_resolver/myfile-list.resolver';
import { MyFileService } from './_services/myfile.service';
import { HelperService } from './_services/helper.service';
import { FormatFileSizePipe } from './_pipe/formatFileSize.pipe';
import { MyFileUploadComponent } from './myfile/myfile-upload/myfile-upload.component';
import { MyFileEditComponent } from './myfile/myfile-edit/myfile-edit.component';
import { MyFileFileResolver } from './_resolver/myfile-file.resolver';
import { Print3dsListComponent } from './print3d/print3ds-list/print3ds-list.component';
import { Print3dsListResolver } from './_resolver/print3d-list.resolver';
import { Print3dService } from './_services/print3d.service';
import { Print3dCardComponent } from './print3d/print3d-card/print3d-card.component';
import { Print3dDetailsComponent } from './print3d/print3d-details/print3d-details.component';
import { Print3dDetailsResolver } from './_resolver/print3d-details.resolver';
import { IMqttMessage, MqttModule, IMqttServiceOptions} from 'ngx-mqtt';

export function tokenGetter() {
   return sessionStorage.getItem('token');
}

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'ep.imse.hku.hk',
  port: 9002,
  path: '/mqtt',
  protocol: 'wss',
  username: 'device1',
  password: 'device1HKU',
  connectOnCreate: true,
};

@NgModule({
   declarations: [
      AppComponent,
      NavComponent,
      HomeComponent,
      RegisterComponent,
      CoursesListComponent,
      CourseCardComponent,
      CourseDetailComponent,
      CourseFileUploadComponent,
      CourseFileEditComponent,
      DocViewerComponent,
      UsersListComponent,
      UsersAddComponent,
      UsersEditComponent,
      AdminComponent,
      CoursesComponent,
      CoursesAddComponent,
      CoursesEditComponent,
      CoursesEnrollmentComponent,
      CoursesEnrollmentAddComponent,
      BatchComponent,
      UserChangePasswordComponent,
      HelpComponent,
      MyFileListComponent,
      MyFileUploadComponent,
      MyFileEditComponent,
      TargetBlankDirective,
      FormatFileSizePipe,
      Print3dsListComponent,
      Print3dCardComponent,
      Print3dDetailsComponent
   ],
   imports: [
      NgbModule,
      BrowserModule,
      HttpClientModule,
      FormsModule,
      FileSaverModule,
      FileUploadModule,
      RouterModule.forRoot(appRoute,
        { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' },
        /*{ enableTracing: true }*/),
      JwtModule.forRoot({
         config: {
            tokenGetter: tokenGetter,
            allowedDomains: ['dp.imse.hku.hk:5000', 'dp.imse.hku.hk:4200', 'dp.imse.hku.hk'],
            disallowedRoutes: ['dp.imse.hku.hk:5000/api/auth', 'dp.imse.hku.hk:4200/api/auth', 'dp.imse.hku.hk/api/auth']
         }
      }),
      MqttModule.forRoot(MQTT_SERVICE_OPTIONS)
   ],
   providers: [
      AuthService,
      ErrorInterceptorProvider,
      AlertifyService,
      AuthGuard,
      UserService,
      CourseService,
      CourseDetailResolver,
      CourseListResolver,
      CourseFileResolver,
      AdminService,
      AdminUsersResolver,
      AdminUserResolver,
      AdminCoursesResolver,
      AdminCourseResolver,
      AdminCourseEnrollmentResolver,
      HelperService,
      MyFileListResolver,
      MyFileFileResolver,
      MyFileService,
      Print3dsListResolver,
      Print3dDetailsResolver,
      Print3dService
   ],
   bootstrap: [
      AppComponent
   ],
   schemas: [
     CUSTOM_ELEMENTS_SCHEMA
   ]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.css']
})
export class UsersAddComponent implements OnInit {
  model: any = {};

  constructor(private adminService: AdminService, private alertify: AlertifyService) {}

  ngOnInit() {
  }

  add(): void {
    this.adminService.addUser(this.model).subscribe(data => {
      this.alertify.success(this.model.displayName + ' registered');
      this.model = {};
    }, error => {
      this.alertify.error(error);
    });
  }
}

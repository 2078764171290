<nav class="navbar navbar-dark bg-dark my-0">
  <span class="text-primary small">Department of Industrial and Manufacturing Systems Engineering</span>
  <span class="text-muted small">The University of Hong Kong</span>
</nav>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" [routerLink]="['']">
    <img src="assets/hku.svg" alt="Logo of HKU" class="img-logo">
  </a>
  <button
    class="navbar-toggler"
    type="button"
    (click)="toggleNavbar()"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div
    class="collapse navbar-collapse"
    id="navbarSupportedContent"
    [ngClass]="{ show: navbarOpen }"
  >
    <div class="mr-auto">
    <ul class="navbar-nav">
      <!-- <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/users']">My Profile</a>
      </li> -->
      <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/courses']" [hidden]="isAdmin()">My Courses</a>
      </li>
      <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/myfiles']" [hidden]="isAdmin()">My Files</a>
      </li>
      <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/print3ds']">3D Printers</a>
      </li>
      <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/admin']" [hidden]="!isAdmin()">Admin Tools</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/help']">Help</a>
      </li>

      <!-- <li ngbDropdown class="nav-item">
        <a
          ngbDropdownToggle
          class="nav-link"
          id="navbarDropdown1"
          role="button"
        >
          My Courses
        </a>
        <div ngbDropdownMenu class="dropdown-menu">
          <div id="dropdown-list">
            <a class="dropdown-item" href="#">None</a>
            <a class="dropdown-item" href="#">1</a>
          </div>
        </div>
      </li> -->
    </ul>
  </div>

    <div *ngIf="loggedIn()" ngbDropdown class="dropdown">
      <a
        ngbDropdownToggle
        class="dropdown-toggle text-light"
        id="navbarDropdown1"
        role="button"
      >
        Welcome {{uname | titlecase}}
      </a>
      <div ngbDropdownMenu class="dropdown-menu">
          <a class="dropdown-item" [routerLink]="['/user/password/change']"><i class="fa fa-user"></i> Change Password</a>
          <a class="dropdown-divider"></a>
          <a class="dropdown-item" href="#" (click)="logout()"><i class="fa fa-sign-out"></i> Logout</a>
      </div>
    </div>

    <form *ngIf="!loggedIn()" #loginForm="ngForm" class="form-inline my-2 my-lg-0" (ngSubmit)="login()">
      <input
        class="form-control mr-sm-2"
        type="text"
        placeholder="Username"
        aria-label="Username"
        [(ngModel)]="model.username"
        required
        name="username"
      />
      <input
        class="form-control mr-sm-2"
        type="password"
        placeholder="Password"
        aria-label="Password"
        [(ngModel)]="model.password"
        required
        name="password"
      />
      <button [disabled]="!loginForm.valid" class="btn btn-success my-2 my-sm-0" type="submit">
        Login</button>
    </form>
  </div>
</nav>

import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/_services/course.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { Datafile } from 'src/app/_models/datafile';
import { FileSaverService } from 'ngx-filesaver';
import { AuthService } from 'src/app/_services/auth.service';
import { HelperService } from 'src/app/_services/helper.service';
import { Course } from 'src/app/_models/course';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css']
})
export class CourseDetailComponent implements OnInit {
  course: Course;
  model: any = {};


  constructor(
    private authService: AuthService,
    private courseService: CourseService,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
    private fileSaver: FileSaverService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.course = data.course;
    });
  }

  applyFilter(): void {
    this.courseService
      .getCourseFiltered(0, this.course.rSize, this.model.keyword, this.course.courseId)
      .subscribe(
        data => {
          this.course = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  clearFilter(): void {
    this.model.keyword = '';
    this.courseService
      .getCourseFiltered(
        0,
        20,
        null,
        this.course.courseId
      )
      .subscribe(
        data => {
          this.course = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  prev(): void {
    const offset = this.course.rOffset - this.course.rSize;

    this.courseService
      .getCourseFiltered(
        offset < 0 ? 0 : offset,
        this.course.rSize,
        this.model.keyword,
        this.course.courseId
      )
      .subscribe(
        data => {
          this.course = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  next(): void {
    this.courseService
      .getCourseFiltered(
        this.course.rOffset + this.course.rSize,
        this.course.rSize,
        this.model.keyword,
        this.course.courseId
      )
      .subscribe(
        data => {
          this.course = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  reload(): void {
    this.courseService
      .getCourseFiltered(
        this.course.rOffset,
        this.course.rSize,
        this.model.keyword,
        this.course.courseId
      )
      .subscribe(
        data => {
          this.course = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  download(dataFile: Datafile) {
    this.courseService.downloadFile(dataFile).subscribe(
      data => {
        if (data != null) {
          this.fileSaver.save(data, dataFile.fileName);
        } else {
          this.alertify.message('Empty file');
        }
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  delete(dataFile: Datafile) {
    this.alertify.confirm('Are you sure you want to delete this file?', () => {
      this.courseService.deleteFile(dataFile).subscribe(
        () => {
          this.alertify.success('File has been deleted');
          if (this.course.dataFiles.length > 1) {
            this.reload();
          } else if (this.course.rOffset > 0) {
            this.prev();
          } else {
            this.course.dataFiles.splice(
              this.course.dataFiles.findIndex(f => f.id === dataFile.id),
              1
            );
          }

        },
        error => {
          this.alertify.error('Failed to delete file');
        }
      );
    });
  }

  isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  isStaff(): boolean {
    return this.authService.isStaff();
  }
  isStudent(): boolean {
    return this.authService.isStudent();
  }

  isAdminOrStaff(): boolean {
    return this.authService.isAdminOrStaff();
  }

  isSupportedImage(type: string): boolean {
    return this.helperService.isSupportedImage(type);
  }

  isSupportedDoc(type: string): boolean {
    return this.helperService.isSupportedDoc(type);
  }

  glbviewer(id) {
    window.open('/view/glb/' + id, 'noopener noreferer');
  }

  gltfviewer(id) {
    window.open('/view/gltf/' + id, 'noopener noreferer');
  }

  objviewer(id) {
    window.open('/view/obj/' + id, 'noopener noreferer');
  }

  stlviewer(id) {
    window.open('/view/stl/' + id, 'noopener noreferer');
  }

  imageviewer(id) {
    window.open('/view/image/' + id, 'noopener noreferer');
  }

  htmlviewer(id) {
    window.open('/view/html/' + id, 'noopener noreferer');
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  model: any = {};
  navbarOpen: boolean;
  uname: 'user';

  constructor(
    private authService: AuthService,
    private alertify: AlertifyService,
    private router: Router
  ) {}

  ngOnInit() {
    this.navbarOpen = false;
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  login() {
    this.authService.login(this.model).subscribe(
      next => {
        this.alertify.success('Logged in successfully');
        if (this.authService.isAdmin()) {
          this.router.navigate(['/admin']);
        } else {
          this.router.navigate(['/courses']);
        }
        this.navbarOpen = false;
      },
      error => {
        this.alertify.error(error);
      }
    );
    // get user name
    this.uname = this.authService.getName();
    // clear user login information
    this.model = {};
  }

  loggedIn() {
    this.uname = this.authService.getName();
    return this.authService.loggedIn();
  }

  logout() {
    sessionStorage.removeItem('token');
    this.alertify.message('Logged out');
    this.uname = 'user';
    this.router.navigate(['/home']);
  }

  isAdmin() {
    return this.authService.isAdmin();
  }
}

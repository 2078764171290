import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/_services/admin.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { CoursePage } from 'src/app/_models/coursepage';
import { Course } from 'src/app/_models/course';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  coursepage: CoursePage;
  model: any = {};

  constructor(
    private adminService: AdminService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.coursepage = data.coursepage;
    });
  }

  isAnyFilterInput(): boolean {
    if (this.model.rCourseCode != null && this.model.rCourseCode.length > 0) { return true; }
    if (this.model.rCourseTitle != null && this.model.rCourseTitle.length > 0) { return true; }
    if (this.model.rYear != null) { return true; }
    if (this.model.rSession != null && this.model.rSession.length > 0) { return true; }
    return false;
  }

  delete(course: Course): void {
    this.alertify.confirm('Are you sure you want to delete this course?', () => {
      this.adminService.deleteCourse(course.courseId).subscribe(
        () => {
          this.alertify.success(course.courseCode + ' removed');
          this.coursepage.courses.splice(
            this.coursepage.courses.findIndex(c => c.courseId === course.courseId),
            1
          );
          this.coursepage.totalSize = this.coursepage.totalSize - 1;
          if (this.coursepage.courses.length === 0) {
            this.prev();
          }
        },
        error => {
          this.alertify.error(error);
        }
      );
    });
  }

  prev(): void {
    const offset = this.coursepage.rOffset - this.coursepage.rSize;

    this.adminService
      .getCoursesFiltered(
        offset < 0 ? 0 : offset,
        this.coursepage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.coursepage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  next(): void {
    this.adminService
      .getCoursesFiltered(
        this.coursepage.rOffset + this.coursepage.rSize,
        this.coursepage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.coursepage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  applyFilter(): void {
    this.adminService
      .getCoursesFiltered(0, this.coursepage.rSize, this.model)
      .subscribe(
        data => {
          this.coursepage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );

  }

  clearFilter(): void {
    this.model = {};
    this.adminService
      .getCoursesFiltered(
        0,
        20,
        null
      )
      .subscribe(
        data => {
          this.coursepage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

}

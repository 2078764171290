import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { Print3dPage } from 'src/app/_models/print3dpage';
import { Print3dService } from 'src/app/_services/print3d.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-print3ds-list',
  templateUrl: './print3ds-list.component.html',
  styleUrls: ['./print3ds-list.component.css']
})
export class Print3dsListComponent implements OnInit {

  print3dpage: Print3dPage;
  model: any = {};

  constructor(
    private print3dservice: Print3dService,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.print3dpage = data.print3dspage;
    });
  }

  applyFilter(): void {
    this.print3dservice
      .getPrint3dsFiltered(0, this.print3dpage.rSize, this.model.keyword)
      .subscribe(
        data => {
          this.print3dpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  clearFilter(): void {
    this.model.keyword = '';
    this.print3dservice
      .getPrint3dsFiltered(
        0,
        20,
        null
      )
      .subscribe(
        data => {
          this.print3dpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  prev(): void {
    const offset = this.print3dpage.rOffset - this.print3dpage.rSize;

    this.print3dservice
      .getPrint3dsFiltered(
        offset < 0 ? 0 : offset,
        this.print3dpage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.print3dpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  next(): void {
    this.print3dservice
      .getPrint3dsFiltered(
        this.print3dpage.rOffset + this.print3dpage.rSize,
        this.print3dpage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.print3dpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

}

import {Injectable} from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { CourseService } from '../_services/course.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Course } from '../_models/course';

@Injectable()
export class CourseDetailResolver implements Resolve<Course> {
    constructor(private courseService: CourseService,
                private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Course> {
        return this.courseService.getCourseFiltered(0, 20, '', route.params.id).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving data');
                this.router.navigate(['/courses']);
                return of(null);
            })
        );
    }
}

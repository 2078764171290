import {Injectable} from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { MyFileService } from '../_services/myfile.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MyFilePage } from '../_models/myfilepage';

@Injectable()
export class MyFileListResolver implements Resolve<MyFilePage[]> {
    constructor(private myfileService: MyFileService,
                private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<MyFilePage[]> {
        return this.myfileService.getMyFileFiltered(0, 20, '').pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving data');
                this.router.navigate(['/home']);
                return of(null);
            })
        );
    }
}

import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/_services/admin.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { EnrollmentPage } from 'src/app/_models/enrollmentpage';
import { EnrollUser } from 'src/app/_models/enrolluser';

@Component({
  selector: 'app-courses-enrollment',
  templateUrl: './courses-enrollment.component.html',
  styleUrls: ['./courses-enrollment.component.css']
})
export class CoursesEnrollmentComponent implements OnInit {
  enrollmentpage: EnrollmentPage;
  model: any = {};

  constructor(
    private adminService: AdminService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.enrollmentpage = data.enrollmentpage;
    });
  }

  deleteStaff(user: EnrollUser): void {
    this.alertify.confirm('Are you sure you want to delete this staff?', () => {
      this.adminService.deleteEnrollment(user.enrollId).subscribe(
        () => {
          this.alertify.success(user.displayName + ' removed');
          this.enrollmentpage.staff.splice(
            this.enrollmentpage.staff.findIndex(u => u.userName === user.userName),
            1
          );
          this.enrollmentpage.totalStaff = this.enrollmentpage.totalStaff - 1;
        },
        error => {
          this.alertify.error(error);
        }
      );
    });
  }

  deleteStudent(user: EnrollUser): void {
    this.alertify.confirm('Are you sure you want to delete this student?', () => {
      this.adminService.deleteEnrollment(user.enrollId).subscribe(
        () => {
          this.alertify.success(user.displayName + ' removed');
          this.enrollmentpage.student.splice(
            this.enrollmentpage.student.findIndex(u => u.userName === user.userName),
            1
          );
          this.enrollmentpage.totalStudent = this.enrollmentpage.totalStudent - 1;
          if (this.enrollmentpage.student.length === 0) {
            this.prev();
          }
        },
        error => {
          this.alertify.error(error);
        }
      );
    });
  }

  prev(): void {
    const offset = this.enrollmentpage.rOffset - this.enrollmentpage.rSize;

    this.adminService
      .getEnrollmentFilter(
        this.enrollmentpage.courseId,
        offset < 0 ? 0 : offset,
        this.enrollmentpage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.enrollmentpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  next(): void {
    this.adminService
      .getEnrollmentFilter(
        this.enrollmentpage.courseId,
        this.enrollmentpage.rOffset + this.enrollmentpage.rSize,
        this.enrollmentpage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.enrollmentpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  applyFilter(): void {
    this.adminService
      .getEnrollmentFilter(this.enrollmentpage.courseId, 0, this.enrollmentpage.rSize, this.model)
      .subscribe(
        data => {
          this.enrollmentpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );

  }

  clearFilter(): void {
    this.model.rKeyword = '';
    this.adminService
      .getEnrollmentFilter(
        this.enrollmentpage.courseId,
        0,
        this.enrollmentpage.rSize,
        null
      )
      .subscribe(
        data => {
          this.enrollmentpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

}

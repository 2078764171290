import {Injectable} from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { CourseService } from '../_services/course.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Datafile } from '../_models/datafile';

@Injectable()
export class CourseFileResolver implements Resolve<Datafile> {
    constructor(private courseService: CourseService,
                private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Datafile> {
        return this.courseService.getDataFile(route.params.cid, route.params.fid).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving data');
                this.router.navigate(['/courses']);
                return of(null);
            })
        );
    }
}

import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/_services/course.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { Datafile } from 'src/app/_models/datafile';

@Component({
  selector: 'app-course-file-edit',
  templateUrl: './course-file-edit.component.html',
  styleUrls: ['./course-file-edit.component.css']
})
export class CourseFileEditComponent implements OnInit {
  dataFile: Datafile;
  model: any = {};

  constructor(
    private courseService: CourseService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.dataFile = data.datafile;
    });

    this.model.filename = this.dataFile.fileName;
    this.model.description = this.dataFile.description;
    this.model.id = this.dataFile.id;
    this.model.courseId = this.dataFile.courseId;
  }

  edit() {
    this.courseService.updateFile(this.model).subscribe(() => {
      this.alertify.success('File updated');
    }, error => {
      this.alertify.error(error);
    });
  }
}

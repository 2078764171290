import { Component, OnInit } from '@angular/core';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { MyFile } from 'src/app/_models/myfile';
import { MyFileService } from 'src/app/_services/myfile.service';

@Component({
  selector: 'app-myfile-edit',
  templateUrl: './myfile-edit.component.html',
  styleUrls: ['./myfile-edit.component.css']
})
export class MyFileEditComponent implements OnInit {
  dataFile: MyFile;
  model: any = {};

  constructor(
    private myfileService: MyFileService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.dataFile = data.myfile;
    });

    this.model.filename = this.dataFile.fileName;
    this.model.description = this.dataFile.description;
    this.model.id = this.dataFile.id;
  }

  edit() {
    this.myfileService.updateFile(this.model).subscribe(() => {
      this.alertify.success('File updated');
    }, error => {
      this.alertify.error(error);
    });
  }
}

import {Injectable} from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Print3dService } from '../_services/print3d.service';
import { Print3d } from '../_models/print3d';

@Injectable()
export class Print3dDetailsResolver implements Resolve<Print3d> {
    constructor(private print3dService: Print3dService,
                private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Print3d> {
        return this.print3dService.getPrint3d(route.params.id).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving data');
                this.router.navigate(['/home']);
                return of(null);
            })
        );
    }
}

<div
  class="card h-100"
  [routerLink]="['/course/', course.courseId]"
>
  <div class="card-img-wrapper">
    <img
      class="card-img-top rounded mx-auto d-block"
      src="assets/3d-modeling.svg"
      alt="course picture"
    />
  </div>
  <div class="card-body p-1">
    <h5 class="card-title text-center mb-1">
      <i class="fa fa-book">
        {{ course.courseCode | uppercase }}
      </i>
    </h5>
    <h6 class="card-title text-center mb-1">
      {{ course.courseTitle }}
    </h6>
    <p class="card-text text-muted text-center">
      {{ course.year }}, {{ course.session }}
    </p>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/_services/admin.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { Course } from 'src/app/_models/course';

@Component({
  selector: 'app-courses-enrollment-add',
  templateUrl: './courses-enrollment-add.component.html',
  styleUrls: ['./courses-enrollment-add.component.css']
})
export class CoursesEnrollmentAddComponent implements OnInit {
  course: Course;
  model: any = {};
  result: any = {};

  constructor(
    private adminService: AdminService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.course = data.course;
    });

  }

  add(): void {
    this.adminService.addEnrollment(this.course.courseId, this.model).subscribe(data => {
      this.result = data;
      if (this.result.failed.length > 0) {
        this.alertify.error('Please review the failed list');
      } else if (this.result.skipped.length > 0) {
        this.alertify.warning('Please review the skipped list');
      } else {
        this.alertify.success('All users are enrolled');
      }
    }, error => {
      this.alertify.error(error);
    });
  }
}

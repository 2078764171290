import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { UserPage } from '../_models/userpage';
import { CoursePage } from '../_models/coursepage';
import { Course } from '../_models/course';
import { EnrollmentPage } from '../_models/enrollmentpage';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  baseUrl = environment.apiUrl + 'admin/';

  constructor(private http: HttpClient) {}

  getUsersFiltered(start: number, size: number, keyword: string): Observable<UserPage> {
    if (keyword == null) {
      return this.http.get<UserPage>(this.baseUrl + 'users/' + start + '/' + size);
    }
    return this.http.get<UserPage>(this.baseUrl + 'users/' + start + '/' + size + '/' + keyword);
  }

  getUser(id): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'users/' + id);
  }

  addUser(model: any) {
    return this.http.post(this.baseUrl + 'users/', model);
  }

  deleteUser(name: string) {
    return this.http.delete(this.baseUrl + 'users/' + name);
  }

  updateUser(model: any) {
    return this.http.put(this.baseUrl + 'users/' + model.userName, model);
  }

  getCoursesFiltered(start: number, size: number, model: any): Observable<CoursePage> {
    if (model == null) {
      return this.http.get<CoursePage>(this.baseUrl + 'courses/' + start + '/' + size);
    }
    return this.http.post<CoursePage>(this.baseUrl + 'courses/' + start + '/' + size, model);
  }

  getCourse(id): Observable<Course> {
    return this.http.get<Course>(this.baseUrl + 'courses/' + id);
  }

  addCourse(model: any) {
    return this.http.post(this.baseUrl + 'courses/', model);
  }

  deleteCourse(id) {
    return this.http.delete(this.baseUrl + 'courses/' + id);
  }

  updateCourse(model: any) {
    return this.http.put(this.baseUrl + 'courses/' + model.courseId, model);
  }

  getEnrollmentFilter(id: number, start: number, size: number,  model: any) {
    if (model == null) {
      return this.http.get<EnrollmentPage>(this.baseUrl + 'courses/enrollment/' + id + '/' + start + '/' + size);
    }
    return this.http.post<EnrollmentPage>(this.baseUrl + 'courses/enrollment/' + id + '/' + start + '/' + size, model);
  }

  addEnrollment(id: number, model: any) {
    return this.http.post(this.baseUrl + 'courses/enrollment/' + id, model);
  }

  deleteEnrollment(eid) {
    return this.http.delete(this.baseUrl + 'courses/enrollment/' + eid);
  }

}

import {Injectable} from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MyFileService } from '../_services/myfile.service';
import { MyFile } from '../_models/myfile';

@Injectable()
export class MyFileFileResolver implements Resolve<MyFile> {
    constructor(private myfileService: MyFileService,
                private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<MyFile> {
        return this.myfileService.getMyFile(route.params.id).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving data');
                this.router.navigate(['/myfiles']);
                return of(null);
            })
        );
    }
}

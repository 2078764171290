import { Pipe, PipeTransform } from '@angular/core';

const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const FILE_SIZE_UNITS_LONG = [
  'Bytes',
  'Kilobytes',
  'Megabytes',
  'Gigabytes',
  'Pettabytes',
  'Exabytes',
  'Zettabytes',
  'Yottabytes'
];

@Pipe({
  name: 'formatFileSize'
})
export class FormatFileSizePipe implements PipeTransform {
  transform(
    sizeInBytes: number = 0,
    precision: number = 2,
    longForm: boolean = false
  ): string {
    const units = longForm ? FILE_SIZE_UNITS_LONG : FILE_SIZE_UNITS;

    if (isNaN(parseFloat(String(sizeInBytes))) || !isFinite(sizeInBytes)) {
      return ' ';
    }

    let unit = 0;

    if (sizeInBytes === 0) {
      return sizeInBytes + ' ' + units[unit];
    }

    while (sizeInBytes >= 1024) {
      sizeInBytes /= 1024;
      unit++;
    }

    return sizeInBytes.toFixed(+precision) + ' ' + units[unit];
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Print3dService } from 'src/app/_services/print3d.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { Print3d } from 'src/app/_models/print3d';
import { environment } from 'src/environments/environment';
import {
  MqttService,
  IMqttMessage,
  IOnConnectEvent,
  IOnErrorEvent,
} from 'ngx-mqtt';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-print3d-details',
  templateUrl: './print3d-details.component.html',
  styleUrls: ['./print3d-details.component.css'],
})
export class Print3dDetailsComponent implements OnInit, OnDestroy {
  print3d: Print3d;
  baseUrl = environment.apiUrl + 'print3d/';
  imageUrl: any;
  interval: any;
  file: any;
  content: any;

  private mqttSub: Subscription;
  private mqttSubImage: Subscription;
  public mqttMsg: string;

  constructor(
    private print3dservice: Print3dService,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
    private mqttService: MqttService
  ) {}

  ngOnInit() {
    this.mqttService.connect();
    this.mqttService.onConnect.subscribe(() => {
      console.log('MQTT Connected');
    });
    this.mqttService.onReconnect.subscribe(() => {
      console.log('MQTT Reconnect...');
    });
    this.mqttService.onError.subscribe((e) => {
      console.log('MQTT Error: ', e);
    });
    console.log('MQTT Sub');
    this.mqttSub = this.mqttService
      .observe('hku/imse/device1/command')
      .subscribe((message: IMqttMessage) => {
        console.log('MQTT Sub Rx');
        this.mqttMsg = message.payload.toString();
        console.log('MQTT Msg:' + this.mqttMsg);
      });

      this.mqttSubImage = this.mqttService
      .observe('hku/imse/device1/image')
      .subscribe((message: IMqttMessage) => {
        console.log('MQTT Sub Image Rx');
        this.mqttMsg = message.payload.toString();
        console.log('MQTT Msg:' + this.mqttMsg);
      });

    this.route.data.subscribe((data) => {
      this.print3d = data.print3d;
    });
    this.imageUrl =
      this.baseUrl +
      'getimage/' +
      this.print3d.edgeDevice +
      '/' +
      this.print3d.photo;
    console.log(this.print3d);

    /* setup timer to get image periodically
    this.interval = setInterval(() => {
      console.log('load data');
      this.print3dservice.getPrint3d(this.print3d.id).subscribe(
        data => {
          this.print3d = data;
          this.imageUrl =
          this.baseUrl +
          'getimage/' +
          this.print3d.edgeDevice +
          '/' +
          this.print3d.photo;
            },
        error => {
          this.alertify.error(error);
        }
      );
    }, 5000);
    */
  }

  ngOnDestroy() {
    console.log('OnDeactivate');
    clearInterval(this.interval);

    console.log('MQTT unSub');
    this.mqttSub.unsubscribe();
  }

  openFile() {
    console.log('hell');
    document.querySelector('input').click();
  }
  handle(e1: any) {
    this.file = e1.target.files[0];
    console.log(this.file);
    const reader = new FileReader();
    reader.onloadend = (e2) => {
      this.content = e2.target.result;
      this.mqttService.unsafePublish('hku/imse/device1/model', this.content, {
        qos: 0,
        retain: true,
      });
    };
    reader.readAsText(this.file);
  }
}

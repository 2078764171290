import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Course } from '../_models/course';
import { HttpClient } from '@angular/common/http';
import { Datafile } from '../_models/datafile';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  baseUrl = environment.apiUrl + 'courses/';

  constructor(private http: HttpClient) {}

  getCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(this.baseUrl);
  }

  getCourseFiltered(start: number, size: number, keyword: string, id: number): Observable<Course> {
    if (keyword == null) {
      return this.http.get<Course>(this.baseUrl + id + '/' + start + '/' + size + '/');
    }
    return this.http.get<Course>(this.baseUrl + id + '/' + start + '/' + size + '/' + keyword);
  }

  getDataFile(cid, fid): Observable<Datafile> {
    return this.http.get<Datafile>(this.baseUrl + cid + '/' + fid);
  }

  downloadFile(df: Datafile): Observable<Blob> {
    const url = this.baseUrl + df.courseId + '/' + df.id + '/' + df.fileName;
    const file = this.http.get<Blob>(url, {responseType: 'blob' as 'json'});

    return file;
  }

  deleteFile(df: Datafile) {
    return this.http.delete(this.baseUrl + df.courseId + '/' + df.id);
  }

  updateFile(model: any) {
    const url = this.baseUrl + model.courseId + '/' + model.id;
    return this.http.put(url, model);
  }

}


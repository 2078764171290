<div class="container-fluid mt-4">
  <!-- Heading -->
  <div class="col text-center border border-primary rounded-pill">
    <h2 class="p-2">User Management</h2>
  </div>
  <!-- Buttons -->
  <div class="row h-100 justify-content-center align-items-center m-4">
    <button
      class="btn btn-primary m-1 text-center"
      [routerLink]="['/admin/users/add']"
    >
      <span class="fa fa-user-plus"></span> Add New User
    </button>
  </div>
  <!-- Table -->
  <div class="col text-center border border-dark rounded-pill">
    <h4 class="p-1">User List</h4>
  </div>
  <div class="row h-100 align-items-center m-4">
    <div class="col-4">
      <button class="btn btn-primary m-1" [disabled]="userpage.rOffset === 0" (click)="prev()">
        <span class="fa fa-step-backward"></span> Previous
      </button>
      <button
        class="btn btn-primary m-1"
        [disabled]="userpage.totalSize <= (userpage.rOffset + userpage.rSize)"
        (click)="next()"
      >
        Next
        <span class="fa fa-step-forward"></span>
      </button>
      <h4 class="align-middle">
        <strong>Users Found: </strong> {{ userpage.totalSize }}
      </h4>
    </div>
    <div class="col-8">
      <form #filterFileForm="ngForm" class="col-12">
        <h6>Filter Login Name, Email, User Name by:</h6>
        <input
          class="form-control"
          placeholder="single keyword"
          aria-label="keyword"
          [(ngModel)]="model.keyword"
          required
          name="keyword"
        />
        <button class="btn btn-primary m-1" [disabled]="!filterFileForm.valid" (click)="applyFilter()">
          <span class="fa fa-filter"></span> Apply Filter
        </button>
        <button class="btn btn-primary m-1" [disabled]="!filterFileForm.dirty" (click)="clearFilter()">
          <span class="fa fa-ban"></span> Clear Filter
        </button>
      </form>
    </div>
  </div>
  <div class="row">
    <!-- course files -->
    <div class="container-fluid m-1">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="tc1">#</th>
            <th class="tc2">Login Name</th>
            <th class="tc3">
              Email <span class="text-primary">[User Name]</span>
            </th>
            <th class="tc4">Description</th>
            <th class="tc5">Type</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of userpage.appUsers; index as i">
            <th scope="row">
              <!-- # -->
              {{ userpage.rOffset + i + 1 }}
            </th>
            <td>
              <!-- Login Name -->
              {{ u.userName }}
            </td>
            <td>
              <!-- Email -->
              <p>{{ u.email }}</p>
              <p class="text-primary">[{{ u.displayName }}]</p>
              <div class="btn-group d-flex">
                <button
                  class="btn btn-primary m-1"
                  [routerLink]="['/admin/users/edit', u.userName]"
                >
                  <i class="fa fa-edit"></i> Edit
                </button>
                <button class="btn btn-primary m-1" (click)="delete(u)">
                  <i class="fa fa-trash"></i> Delete
                </button>
              </div>
            </td>
            <td>
              <!-- Description -->
              <textarea rows="3">{{ u.description }}</textarea>
            </td>
            <td>
              <!-- Type -->
              {{ u.type }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { Print3d } from 'src/app/_models/print3d';

@Component({
  selector: 'app-print3d-card',
  templateUrl: './print3d-card.component.html',
  styleUrls: ['./print3d-card.component.css']
})
export class Print3dCardComponent implements OnInit {

  @Input() print3d: Print3d;

  constructor() { }

  ngOnInit() {
  }

}

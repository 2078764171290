<div class="container-fluid mt-4">
  <!-- Heading -->
  <div class="col text-center border border-primary rounded-pill">
    <h2 class="p-2">Course Enrollment Management</h2>
  </div>
  <!-- course information -->
  <div class="row m-2">
    <div class="col-sm-1">
      <div class="icon-wrapper text-center mt-2">
        <img src="assets/3d-modeling.svg" alt="course picture" class="icon" />
      </div>
    </div>
    <div class="col-sm-11">
      <h3>
        <strong>Code:</strong> {{ enrollmentpage.courseCode | uppercase }}
        <strong>Year:</strong> {{ enrollmentpage.year }}
        <strong>Session:</strong>
        {{ enrollmentpage.session }}
      </h3>
      <h4><strong>Description:</strong> {{ enrollmentpage.courseTitle }}</h4>
      <button
        class="btn btn-primary m-1 text-center"
        [routerLink]="[
          '/admin/courses/enrollment/add',
          enrollmentpage.courseId
        ]"
      >
        <span class="fa fa-graduation-cap"></span> Add New Enrollment
      </button>
    </div>
  </div>
  <!-- Staff Table -->
  <div class="col text-center border border-dark rounded-pill">
    <h4 class="p-1">Staff List</h4>
  </div>
  <div class="row">
    <!-- Staff List -->
    <div class="container-fluid m-1">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="tc1">#</th>
            <th class="tc2">Login Name</th>
            <th class="tc3">User Name</th>
            <th class="tc4">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let s of enrollmentpage.staff; index as i">
            <th scope="row">
              <!-- # -->
              {{ enrollmentpage.rOffset + i + 1 }}
            </th>
            <td>
              <!-- Login Name -->
              {{ s.userName }}
            </td>
            <td>
              <!-- User Name -->
              <p>{{ s.displayName }}</p>
            </td>
            <td>
              <!-- Buttons -->
              <div class="btn-group d-flex">
                <button class="btn btn-primary m-1" (click)="deleteStaff(s)">
                  <i class="fa fa-trash"></i> Delete
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Student Table -->
  <div class="col text-center border border-dark rounded-pill">
    <h4 class="p-1">Student List</h4>
  </div>
  <div class="row h-100 align-items-center m-4">
    <div class="col-4">
      <button
        class="btn btn-primary m-1"
        [disabled]="enrollmentpage.rOffset === 0"
        (click)="prev()"
      >
        <span class="fa fa-step-backward"></span> Previous
      </button>
      <button
        class="btn btn-primary m-1"
        [disabled]="
          enrollmentpage.totalStudent <=
          enrollmentpage.rOffset + enrollmentpage.rSize
        "
        (click)="next()"
      >
        Next
        <span class="fa fa-step-forward"></span>
      </button>
      <h4 class="align-middle">
          <strong [hidden]="enrollmentpage.rKeyword != null">Student Enrolled: </strong>
          <strong [hidden]="enrollmentpage.rKeyword === null">Student Filtered: </strong>
          {{ enrollmentpage.totalStudent }}
      </h4>
    </div>
    <div class="col-8">
      <form #filterFileForm="ngForm" class="col-12">
        <h6>Filter Login Name by:</h6>
        <input
          class="form-control"
          placeholder="single keyword"
          aria-label="keyword"
          [(ngModel)]="model.rKeyword"
          required
          name="keyword"
        />
        <button
          class="btn btn-primary m-1"
          [disabled]="!filterFileForm.valid"
          (click)="applyFilter()"
        >
          <span class="fa fa-filter"></span> Apply Filter
        </button>
        <button
          class="btn btn-primary m-1"
          [disabled]="!filterFileForm.dirty"
          (click)="clearFilter()"
        >
          <span class="fa fa-ban"></span> Clear Filter
        </button>
      </form>
    </div>
  </div>
  <div class="row">
    <!-- Student List -->
    <div class="container-fluid m-1">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="tc1">#</th>
            <th class="tc2">Login Name</th>
            <th class="tc3">User Name</th>
            <th class="tc4">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let s of enrollmentpage.student; index as i">
            <th scope="row">
              <!-- # -->
              {{ enrollmentpage.rOffset + i + 1 }}
            </th>
            <td>
              <!-- Login Name -->
              {{ s.userName }}
            </td>
            <td>
              <!-- User Name -->
              <p>{{ s.displayName }}</p>
            </td>
            <td>
              <!-- Buttons -->
              <div class="btn-group d-flex">
                <button class="btn btn-primary m-1" (click)="deleteStudent(s)">
                  <i class="fa fa-trash"></i> Delete
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="container-fluid mt-4">
  <!-- Heading -->
  <div class="col text-center border border-primary rounded-pill">
    <h2 class="p-2">Password Change</h2>
  </div>
  <!-- Form -->
  <div class="row h-100 justify-content-center align-items-center m-4">
    <form #changePasswordForm="ngForm" class="col-12">
      <h4>Current Password</h4>
      <input
        type="password"
        class="form-control"
        placeholder="Current Password"
        aria-label="current password"
        [(ngModel)]="model.current"
        required
        name="currentpassword"
      />
      <h4>New Password</h4>
      <input
        type="password"
        class="form-control"
        required
        name="newpassword"
        placeholder="New Password"
        [(ngModel)]="model.password"
      />
      <h4>Verify New Password</h4>
      <input
        type="password"
        class="form-control"
        placeholder="Verify New Password"
        aria-label="verify new password"
        [(ngModel)]="model.password2"
        required
        name="verifypassword"
      />

      <hr />
      <button
        class="btn btn-primary m-1"
        [disabled]="!changePasswordForm.valid || model.password != model.password2"
        (click)="changePassword()"
      >
        <span class="fa fa-plus-circle"></span> Submit
      </button>
    </form>
  </div>
</div>

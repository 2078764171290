<div class="container mt-5">
  <div class="row">

    <div class="col border rounded-circle bg-warning text-center" [hidden]="courses.length != 0">
      <h4 class="p-1 text-white">
        No course enrolled
      </h4>
    </div>

    <div *ngFor="let course of courses" class="col-sm-4 col-md-3 p-2">
      <app-course-card [course]="course"></app-course-card>
    </div>
  </div>
</div>

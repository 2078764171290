import { Component, OnInit } from '@angular/core';
import { Datafile } from 'src/app/_models/datafile';
import { CourseService } from 'src/app/_services/course.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.css']
})
export class DocViewerComponent implements OnInit {

  dataFile: Datafile;
  docUrl: any = '';
  docType: any = '';
  pdfUrl: any = '';

  constructor(
    private courseService: CourseService,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.dataFile = data.datafile;
    });
    // download file as objectUrl
    this.courseService.downloadFile(this.dataFile).subscribe(
      data => {
        this.setDocType(this.dataFile.fileType);
        const unsafeDocUrl = URL.createObjectURL(data);
        this.docUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeDocUrl);
    }, error => {
        // console.log(error);
    });

    // this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/hello.pdf');
  }

  setDocType(type: string) {
    switch (type) {
      case 'doc':
        this.docType = 'application/doc';
        break;
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'ppt':
      case 'pptx':
      case 'txt':
      case 'pdf':
        this.docType = 'application/pdf';
        break;
      default:
        this.docType = 'application/xml';
    }
  }

}

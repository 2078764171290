<div class="container-fluid mt-4">
  <!-- Edit File -->
  <div class="row m-2">
    <form #editFileForm="ngForm" class="col-12">
      <h4>File Name</h4>
      <input
        class="form-control"
        placeholder="File Name"
        aria-label="filename"
        [(ngModel)]="model.filename"
        required
        name="filename"
      />
      <h4>File Description</h4>
      <textarea
        class="form-control textHeight"
        rows="12"
        placeholder="Description"
        aria-label="description"
        [(ngModel)]="model.description"
        required
        name="description"
      ></textarea>
      <button
        type="button"
        class="btn btn-success btn-s m-2"
        (click)="edit()"
        [disabled]="!editFileForm.valid"
      >
        <span class="fa fa-pencil"></span> Update
      </button>
    </form>
  </div>
</div>

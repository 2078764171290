import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl + 'auth/';
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  constructor(private http: HttpClient) {}

  decodeToken() {
    const token = sessionStorage.getItem('token');
    if (token) {
      this.decodedToken = this.jwtHelper.decodeToken(token);
    }
  }

  getName(): any {
    if (this.decodedToken) {
      const uname = this.decodedToken.name;
      if (uname) {
        return uname;
      }
    }
    return 'user';
  }

  getType(): any {
    if (this.decodedToken.type) {
      const type = this.decodedToken.type;
      if (type) {
        return type;
      }
      return 'unknown';
    }
  }

  isAdmin(): boolean {
    const type = this.decodedToken.type;
    if (type === 'admin') {
      return true;
    }
    return false;
  }

  isStaff(): boolean {
    const type = this.decodedToken.type;
    if (type === 'staff') {
      return true;
    }
    return false;
  }

  isStudent(): boolean {
    const type = this.decodedToken.type;
    if (type === 'student') {
      return true;
    }
    return false;
  }

  isAdminOrStaff(): boolean {
    const type = this.decodedToken.type;
    if (type === 'admin') {
      return true;
    }
    if (type === 'staff') {
      return true;
    }
    return false;
  }

  login(model: any) {
    return this.http.post(this.baseUrl + 'login', model).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          sessionStorage.setItem('token', user.token);
          this.decodedToken = this.jwtHelper.decodeToken(user.token);
        }
      })
    );
  }

  changePassword(model: any) {
    return this.http.post(this.baseUrl + 'password', model);
  }

  register(model: any) {
    return this.http.post(this.baseUrl + 'register', model);
  }

  loggedIn() {
    const token = sessionStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }
}

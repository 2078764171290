import { Component, OnInit } from '@angular/core';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { UserPage } from 'src/app/_models/userpage';
import { User } from 'src/app/_models/user';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-users',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
  userpage: UserPage;
  model: any = {};

  constructor(
    private adminService: AdminService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.userpage = data.userpage;
    });
  }

  applyFilter(): void {
    this.adminService
      .getUsersFiltered(0, this.userpage.rSize, this.model.keyword)
      .subscribe(
        data => {
          this.userpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  clearFilter(): void {
    this.model.keyword = '';
    this.adminService
      .getUsersFiltered(
        0,
        20,
        null
      )
      .subscribe(
        data => {
          this.userpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  prev(): void {
    const offset = this.userpage.rOffset - this.userpage.rSize;

    this.adminService
      .getUsersFiltered(
        offset < 0 ? 0 : offset,
        this.userpage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.userpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  next(): void {
    this.adminService
      .getUsersFiltered(
        this.userpage.rOffset + this.userpage.rSize,
        this.userpage.rSize,
        this.model.keyword
      )
      .subscribe(
        data => {
          this.userpage = data;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  delete(user: User): void {
    this.alertify.confirm('Are you sure you want to delete this user?', () => {
      this.adminService.deleteUser(user.userName).subscribe(
        () => {
          this.alertify.success(user.displayName + ' removed');
          this.userpage.appUsers.splice(
            this.userpage.appUsers.findIndex(u => u.userName === user.userName),
            1
          );
          this.userpage.totalSize = this.userpage.totalSize - 1;
          if (this.userpage.appUsers.length === 0) {
            this.prev();
          }
        },
        error => {
          this.alertify.error(error);
        }
      );
    });
  }
}

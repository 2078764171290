<div class="container mt-4">
  <div class="col text-center border border-primary rounded-pill">
    <h2 class="p-2">Administration Tools</h2>
  </div>
  <div class="row card-group justify-content-center mt-4">
      <div class="col-sm-6 col-md-4 p-2">
        <div class="card h-100" [routerLink]="['/admin/users']">
          <div class="card-img-wrapper">
            <img
              class="card-img-top rounded mx-auto d-block"
              src="assets/3d-modeling.svg"
              alt="course picture"
            />
          </div>
          <div class="card-body p-4">
            <h5 class="card-title text-center mb-1">
              User Management
            </h5>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 p-2">
        <div class="card h-100" [routerLink]="['/admin/courses']">
          <div class="card-img-wrapper ">
            <img
              class="card-img-top rounded mx-auto d-block"
              src="assets/3d-modeling.svg"
              alt="course picture"
            />
          </div>
          <div class="card-body p-4">
            <h5 class="card-title text-center mb-1">
              Course Management
            </h5>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 p-2">
        <div class="card h-100" [routerLink]="['/admin/batch']">
          <div class="card-img-wrapper">
            <img
              class="card-img-top rounded mx-auto d-block"
              src="assets/3d-modeling.svg"
              alt="course picture"
            />
          </div>
          <div class="card-body p-4">
            <h5 class="card-title text-center mb-1">
              Batch Operation
            </h5>
          </div>
        </div>
      </div>
    </div>
  
</div>

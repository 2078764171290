<div class="container-fluid mt-4">
  <!-- course information -->
  <div class="row m-2">
    <div class="col-sm-1">
      <div class="icon-wrapper text-center mt-2">
        <img src="assets/3d-modeling.svg" alt="course picture" class="icon" />
      </div>
    </div>
    <div class="col-sm-11">
      <h3>
        <strong>Code:</strong> {{ course.courseCode | uppercase }}
        <strong>Year:</strong> {{ course.year }} <strong>Session:</strong>
        {{ course.session }}
      </h3>
      <h4><strong>Description:</strong> {{ course.courseTitle }}</h4>
    </div>
  </div>
  <!-- row -->
  <div class="row mt-3">
    <div class="container-fluid">
      <!-- course file upload -->
      <form #uploadFileForm="ngForm">
        <h4>File Description</h4>
        <textarea
          class="form-control textHeight"
          rows="6"
          placeholder="Description"
          aria-label="description"
          [(ngModel)]="model.description"
          required
          name="description"
        ></textarea>
        <input
          class="form-control mr-sm-2"
          type="hidden"
          placeholder="Course ID"
          aria-label="courseId"
          [(ngModel)]="model.courseId"
          required
          [contentEditable]="false"
          name="courseId"
        />
      </form>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-3">
      <h3>Add File</h3>

      <!-- drop zone -->
      <div
        ng2FileDrop
        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
        (fileOver)="fileOverBase($event)"
        [uploader]="uploader"
        class="card bg-faded p-3 text-center my-drop-zone"
      >
        <i class="fa fa-upload fa-3x"></i>
        Drop File Here
      </div>

      <!-- file button -->
      <input type="file" ng2FileSelect [uploader]="uploader" />
    </div>

    <div class="col-md-9" style="margin-bottom: 40px" *ngIf="uploader?.queue?.length">
      <h3>Upload queue</h3>
      <p>Queue length: {{ uploader?.queue?.length }}</p>

      <table class="table">
        <thead>
          <tr>
            <th width="80%">Name</th>
            <th>Size</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of uploader.queue">
            <td>
              <strong>{{ item?.file?.name }}</strong>
            </td>
            <td nowrap>
              {{ item?.file?.size / 1024 / 1024 | number: ".2" }} MB
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <div>
          Queue progress:
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              [ngStyle]="{ width: uploader.progress + '%' }"
            ></div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-success btn-s"
          (click)="upload()"
          [disabled]="
            !uploader.getNotUploadedItems().length || !uploadFileForm.valid
          "
        >
          <span class="fa fa-upload"></span> Upload
        </button>
        <button
          type="button"
          class="btn btn-warning btn-s"
          (click)="uploader.cancelAll()"
          [disabled]="!uploader.isUploading"
        >
          <span class="fa fa-ban"></span> Cancel
        </button>
        <button
          type="button"
          class="btn btn-danger btn-s"
          (click)="uploader.clearQueue()"
          [disabled]="!uploader.queue.length"
        >
          <span class="fa fa-trash"></span> Remove
        </button>
      </div>
    </div>
    <!-- row -->
  </div>
</div>
